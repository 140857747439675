<script setup lang="ts">
  import Survey from '@/core/store/survey/surveyStore';
  import { DisplayType, FieldType, ItemMultiDrawarer } from '@/core/store/survey/surveyDesignDtos';
  import { computed, nextTick, ref } from 'vue';
  import Dialog from '../../dialog.vue';
import { emitKeypressEvents } from 'readline';
  const {currentItem, bindItem, getNewTag, findTag} = Survey();
    const props = defineProps({
      show: Boolean,
      onClose: Function,
    });

  const withTag = computed<boolean>(() => {
    return currentItem.value.type == FieldType.GRID;
  });
  const items = computed<ItemMultiDrawarer[]>(() => {
    return currentItem.value.items ?? [];
  })

  const del = (item: ItemMultiDrawarer) => {
    const its = [...items.value];
    
    const idx = items.value.indexOf(item);
    if(idx!==-1){
      its.splice(idx, 1);
      bindItem("item", DisplayType.EDIT, "contentStyle", 'items', its);  
    }
  }
  const tableDiv = ref<HTMLElement | null>(null);
  const validChageTag = (i, elem: HTMLInputElement) =>{
    if(i.tag !== elem.value){
      if(findTag(elem.value)){
        elem.value = i.tag;
        return;
      }
    }
    i.tag = elem.value;
  }
  const add = () => {
    const item = new ItemMultiDrawarer();
    if(currentItem.value.type === FieldType.GRID){
      item.tag = getNewTag();
    }

    bindItem("item", DisplayType.EDIT, "contentStyle", 'items', [...items.value, item]);
    if(tableDiv.value){
      nextTick(() => tableDiv.value.scrollTop = tableDiv.value.scrollHeight )
    }
  }
</script>

<template>
  <Dialog :show="props.show" :onClose="props.onClose">
    <div class="survey-item-select-container">
      <div class="survey-item-select-header">
        <button class="add-button" @click="add">
          <i class="bi bi-plus"></i>
        </button>
        <div class="survey-item-select-header-head">
          Elementos {{ currentItem.type == FieldType.GRID ? 'de datos tabulados' : 'del selector'}}
        </div>     
      </div>  
      <div class="survey-item-select-table" ref="tableDiv">
          <table class="survey-item-table">
            <thead>
              <tr>
                <th class="survey-item-table-header">Etiqueta</th>
                <th class="survey-item-table-header">Peso</th>
                <th v-if="withTag" class="survey-item-table-header">Variable</th>
                <th class="survey-item-table-header deleteHeader">Eliminar</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(i, index) in items" :key="index" class="survey-item-table-row">
                <td><input type="text" v-model="i.label" class="survey-item-input"/></td>
                <td><input type="number" v-model="i.value" class="survey-item-input"/></td>
                <td v-if="withTag">
                    <input type="text" 
                          class="survey-item-input"
                          :value="i.tag"  
                          @change="
                          ($event: Event) => { 
                            validChageTag(i, (($event.target) as HTMLInputElement))

                          }" /></td>
                <td>
                  <button class="deleteSurveyItemBTN" @click="del(i)">
                    <i class="bi bi-trash3"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
      </div>
    </div>
  </Dialog>
</template>

<style scoped>

.survey-item-select-container {
  background-color: #D3EDE6;
  padding: 20px;
  border-radius: 20px;
  width: 500px;
  max-height: 500px;
}

.survey-item-select-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.add-button {
  background-color: #FAA476;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.add-button i {
  font-size: 40px;
  margin-top: 4px;
}

.survey-item-select-header-head {
  font-size: 1.2em;
  font-weight: bold;
  color: #083536;
  margin-left: 20px;
  margin-top: 5px;
}

.survey-item-select-table {
  max-height: 500px;
  overflow-y: auto;
  background-color: #fff;
  /* box-shadow: inset -20px 0 60px -20px #D3EDE6; */
  border-radius: 20px;
  padding: 20px;
}

.survey-item-select-table::-webkit-scrollbar {
  width: 6px;
  background-color: #EDF5F3;

}

.survey-item-select-table::-webkit-scrollbar-thumb {
  background-color: #12999D;
  border-radius: 5px;
}

.survey-item-select-table::-webkit-scrollbar-thumb:hover {
  background-color: #FAA476;

}

.survey-item-select-table::-webkit-scrollbar-track {
  /* background-color: #D3EDE6; */
  background-color: #B7DDDE;
  border-radius: 5px;
}

.survey-item-table {
  width: 100%;
}

.survey-item-table-header {
  padding: 10px;
  text-align: left;
}

.survey-item-table-row {
  border-bottom: 1px solid #083536;
}

.survey-item-input {
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
  background-color: transparent;
}

.deleteHeader {
  width: 50px;
  padding-right: 10px;
}

.deleteSurveyItemBTN {
  background-color: red;
  color: white;
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 14px;
}

.deleteSurveyItemBTN i {
  font-size: 15px;
}
</style>

<script lang="ts">
  export default {
    name: 'SurveyItemSelect'
  }
</script>
